import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Column } from "react-table";
import { SyncLogRecord } from "../models/sync-log-record.model";
import { getLogsPaginated } from "../store/sync-sessions-actions";

import "./sync-session-logs.scss";
import { Table, TableProps } from "./table.component";

export const SyncSessionLogsComponent = ({
  sessionId,
}: {
  sessionId: string;
}) => {
  const logs: SyncLogRecord[] = useSelector(
    (state: any) => state.syncSessions.logs
  );
  const totalCount: number = useSelector(
    (state: any) => state.syncSessions.logsTotalCount
  );
  const pageCount: number = useSelector(
    (state: any) => state.syncSessions.logsPageCount
  );

  const columns: Column[] = useMemo(() => logsColumns, []);

  const data = useMemo(
    () =>
      logs?.map((log: SyncLogRecord) => {
        return {
          ...log,
          fileRowNumber: log.fileRowNumber,
          targetedObjectName: log.targetedObjectName,
          lastModifiedDate: getLastModifiedDateLabel(log.lastModifiedDate),
          messages: prepareMessages(log.messages),
          status: log.status?.name,
        };
      }) ?? [],
    [logs]
  );

  const requestDataCallBack = useCallback(
    (arg: any) => getLogsPaginated(arg),
    []
  );
  const dataPayload = useMemo(() => {
    return { sessionId };
  }, [sessionId]);

  const tableProps: TableProps = {
    columns: columns,
    data: data,
    tableId: "syncSessionLogs-table",
    requestDataCallBack: requestDataCallBack, //change to typed lambda?
    totalCount: totalCount,
    pageCounter: pageCount,
    dataPayload: dataPayload,
    showRefreshButton: true,
  };
  return (
    <div className="syncSessionLogs">
      <Table {...tableProps} />
    </div>
  );
};

const logsColumns = [
  {
    Header: "Row Number",
    accessor: "fileRowNumber",
  },
  {
    Header: "Identifier",
    accessor: "targetedObjectName",
  },
  {
    Header: "Last Modified Date",
    accessor: "lastModifiedDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Messages",
    accessor: "messages",
    defaultCanSort: false,
    disableSortBy: true,
  },
];

const prepareMessages = (messages: string[]) => (
  <>{messages?.map((m, index) => <p key={index}>{m}</p>)}</>
);

const getLastModifiedDateLabel = (date: Date) => {
  return <label>{date?.toLocaleString() ?? "—"} </label>;
};
