import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { Table, TableProps } from "../components/table.component";
import { Column } from "react-table";
import { ScimLogRecord } from "../models/scim-log-record.model";
import { getLogsPaginated } from "../store/scim-history-actions";
import "./ScimLogs.scss";

export const ScimLogsPage = () => {
  const logs: ScimLogRecord[] = useSelector(
    (state: any) => state.scimHistory.logs
  );
  const totalCount: number = useSelector(
    (state: any) => state.scimHistory.logsTotalCount
  );
  const pageCount: number = useSelector(
    (state: any) => state.scimHistory.logsPageCount
  );

  const columns: Column[] = useMemo(() => logsColumns, []);

  const data = useMemo(
    () =>
      logs?.map((log: ScimLogRecord) => {
        return {
          requestId: log.requestId,
          targetedObjectName: log.targetIdentifier,
          operationType: log.operationType?.name,
          lastModifiedDate: getLastModifiedDateLabel(log.lastModifiedDate),
          messages: prepareMessages(log.messages),
          status: log.status?.name,
        };
      }) ?? [],
    [logs]
  );

  const requestDataCallBack = useCallback(
    (arg: any) => getLogsPaginated(arg),
    []
  );
  const dataPayload = useMemo(() => ({}), []);

  const tableProps: TableProps = {
    columns,
    data,
    tableId: "scim-logs-table",
    requestDataCallBack,
    totalCount,
    dataPayload,
    pageCounter: pageCount,
    showRefreshButton: true,
  };

  return (
    <div className="scim-logs-page" data-testid="scim-logs">
      <h3 className="header">SCIM Logs</h3>
      <div className="scim-logs-container">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export const logsColumns = [
  {
    Header: "Request ID",
    accessor: "requestId",
  },
  {
    Header: "Identifier",
    accessor: "targetedObjectName",
  },
  {
    Header: "Operation",
    accessor: "operationType",
  },
  {
    Header: "Last Modified Date",
    accessor: "lastModifiedDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Messages",
    accessor: "messages",
    defaultCanSort: false,
    disableSortBy: true,
  },
];

const prepareMessages = (messages: string[]) => (
  <>{messages?.map((m, index) => <p key={index}>{m}</p>)}</>
);

const getLastModifiedDateLabel = (date: Date) => {
  return <label>{date?.toLocaleString() ?? "—"} </label>;
};
